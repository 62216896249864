import {defineComponent, computed, PropType, toRefs, ref, watch, reactive, toRaw, onMounted, onBeforeUpdate, h,
  onUpdated,
  isRef,
  onBeforeUnmount, nextTick, inject, Ref} from "vue"
import {useI18n} from "vue-i18n"
import UsersList from "@/components/UsersList.vue"
import UserAvatar from "@/components/UserAvatar"
import MenuUploadFiles from "@/components/Menus/MenuUploadFiles.vue"
import NoteUploader from "@/components/NoteUploader"
import ApiService from "@/services/api_service"
import {VueUploadItem} from "vue-upload-component"
import {IData, IFieldErrors, INote, INoteFormDraft, IUser} from "@/types"
import NotesService from "@/services/notes_service"
import _merge from 'lodash/merge';
import {useStore} from "vuex"
import {usePasteUpload} from "@/composables/usePasteUpload"
import {useFancybox} from "@/composables/useFancybox"
import {onBeforeRouteUpdate} from "vue-router"
import RichEditor from "@/components/RichEditor.vue"
import {useFormErrors} from "@/composables/useFormErrors"
import SeekerUsers from '@/components/Seekers/SeekerUsers.vue'
import SvgIcon from "@/components/SvgIcon/SvgIcon.vue";
import {usePositionPopup} from "@/composables/usePositionPopup"
import _uniqueId from "lodash/uniqueId"
import {useFloatingExists} from "@/composables/useFloatingExists"
import VMenuSeparator from '@/components/Menus/VMenuSeparator.vue'
import VMenuItem from '@/components/Menus/VMenuItem.vue'
import VMenu from '@/components/Menus/VMenu.vue'
import {useAppUtils} from "@/composables/useAppUtils"
import {autorefreshBlockAdd, autorefreshBlockExists, autorefreshBlockRemove} from "@/composables/useFetchState"
import _cloneDeep from "lodash/cloneDeep"
import {useNoteDraft} from "@/composables/useNoteDraft"
import {useEventListener} from "@vueuse/core"
import {useFilesDrag} from "@/composables/useFilesDrag"
import FloatingContainer from '@/components/Popups/FloatingContainer.vue'
import SeekersService from '@/services/seekers_service'

export default defineComponent({
  name: 'NoteEditForm',
  props: {
    note: {
      type: null, //Object as PropType<INote>,
      required: false,
    },
    isNew: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      required: false,
    },
    reply: {
      type: String,
      required: false,
    },
    positionNote: {
      type: String,
      required: false,
    },
    ticket_id: {
      type: String,
      required: false,
    },
    minimized: {
      type: Boolean,
      default: false
    },
  },
  emits: [
    'cancel',
    'save',
    'beforeSave',
    'saveSuccess',
    'saveError',
    'hasChanges',
    'changeUploadActive',
    'makeCover',
  ],

  components: {
    UserAvatar,
    UsersList,
    MenuUploadFiles,
    NoteUploader,
    RichEditor,
    SvgIcon,
    SeekerUsers,
    VMenu,
    VMenuItem,
    VMenuSeparator,
  },

  setup(props, {emit}) { //props, context


    const { t } = useI18n()
    const store = useStore()

    const formUid = ref(Date.now() + '_' + Math.round(Math.random() * 1000000)) // VARCHAR(32)
    const uploaderInputId = ref(formUid.value + '_uploader')
    const files = ref<VueUploadItem[]>([])
    const uploadingFilesWithThumb = computed(() => files.value.filter(item => item.canThumb))
    const uploadingFilesWithoutThumb = computed(() => files.value.filter(item => !item.canThumb))
    const refNoteUploader = ref<InstanceType<typeof NoteUploader> | null>(null)
    const hasAttachments = computed(() => noteObj.value?.files?.imageList?.length || noteObj.value?.files?.fileList?.length || uploadingFilesWithThumb.value.length || uploadingFilesWithoutThumb.value.length)
    const dropActive = computed(() => (refNoteUploader.value && refNoteUploader.value.dropActive || filesDraggingActive.value) && isActiveForUpload())
    const refDropElement = ref(null)
    const justAddedAttachmentsWithoutErrorCount = computed(() => files.value.filter(fileItem => !fileItem.error).length)
    const activeAttachmentsCount = computed(() => {
      const savedBeforeWithThumbCount = noteObj.value?.files?.imageList?.length || 0
      const savedBeforeWithoutThumbCount = noteObj.value?.files?.fileList?.length || 0
      return savedBeforeWithThumbCount + savedBeforeWithoutThumbCount + justAddedAttachmentsWithoutErrorCount.value
    })
    const addingAttachmentsDisabled = computed(() => activeAttachmentsCount.value >= filesCountLimit)
    const { positionRightStartFlipShift } = usePositionPopup(props)
    const authUser = computed(() => store.getters['auth/authUser'])
    const storageLimitReached = computed(() => store.getters.storageLimitReached)
    const ticketCoverEnabled = computed<boolean>(() => store.getters['settings/show_cover'])

    // const uploaderData = {
    //   ... ApiService.getDefaultParams(),
    //   //name: '',
    //   form_id: formUid,
    //   ticket_id: props.ticket_id,
    // }

    const uploaderData = computed(() => ({
      ... ApiService.getDefaultParams(),
      //name: '',
      form_id: formUid.value,
      ticket_id: props.ticket_id,
    }))

    const filesCountLimit = 20
    const fileSizeLimit = store.state.config.upload_max_filesize

    let initialNoteData: INote | null = null
    const noteObj = ref<INote | null>(null)
    const isMinimized = ref(props.minimized)
    const htmlContent = ref(props.note?.text || '')
    const initialHtmlContent = ref(htmlContent.value)
    const isChangedHtmlContent = ref(false)
    const isPrivate = ref<null | number | string>(props.note?.private)
    const isEmailNotify = ref(props.note?.notify_users && props.note?.users && props.note?.users?.length ? 1 : 0)
    const initialIsEmailNotify = ref(isEmailNotify.value)
    const emailIds = ref(props.note?.email_ids || '')
    const initialEmailIds = ref(emailIds.value)
    const emitter: any = inject('emitter')

    const hasAttachmentsWithThumbs = computed(() => noteObj.value?.files?.imageList?.length || uploadingFilesWithThumb.value.length)
    const hasAttachmentsWithoutThumbs = computed(() => noteObj.value?.files?.fileList?.length || uploadingFilesWithoutThumb.value.length)

    const hasChanges = computed(() => {
      console.log('NoteEditForm hasChanges %%%%%', justAddedAttachmentsWithoutErrorCount.value, isChangedHtmlContent.value, noteObj.value?.id, noteObj.value)
      // Если в НОВОМ ноуте нет текста или файлов, считаем его пустым (не измененным)
      if (!noteObj.value?.id && justAddedAttachmentsWithoutErrorCount.value === 0 && !isChangedHtmlContent.value) {
        return false
      }
      const isFalsyPrivateNew = !isPrivate.value || isPrivate.value === 0 || isPrivate.value === '0'
      const isFalsyPrivateOld = !noteObj.value?.private || noteObj.value?.private === 0 || noteObj.value?.private === '0'

      const emailIdsNew = JSON.stringify(emailIds.value.split(',').sort())
      const emailIdsOld = JSON.stringify(initialEmailIds.value.split(',').sort())

      const isEmailNotifyNew = isEmailNotify.value
      const isEmailNotifyOld = initialIsEmailNotify.value

      console.log('setNoteFormChanged computed hasChanges.value', hasChanges.value,
        justAddedAttachmentsWithoutErrorCount.value,
        isChangedHtmlContent.value, htmlContent.value, initialHtmlContent.value,
        isFalsyPrivateNew !== isFalsyPrivateOld, isFalsyPrivateNew, isFalsyPrivateOld, isPrivate.value, typeof isPrivate.value
        )
      console.log('setNoteFormChanged computed hasChanges.value emailIds', emailIdsNew !== emailIdsOld, emailIdsNew, emailIdsOld)
      console.log('setNoteFormChanged computed hasChanges.value isEmailNotify', isEmailNotifyNew !== isEmailNotifyOld, isEmailNotifyNew, isEmailNotifyOld)
      return justAddedAttachmentsWithoutErrorCount.value > 0 ||
        isChangedHtmlContent.value ||
        isFalsyPrivateNew !== isFalsyPrivateOld ||
        emailIdsNew !== emailIdsOld ||
        isEmailNotifyNew !== isEmailNotifyOld
    })

    const resetInitialValues = () => {
      if (noteObj.value) {
        noteObj.value.private = isPrivate.value
      }
      initialEmailIds.value = _cloneDeep(emailIds.value)
      initialIsEmailNotify.value = isEmailNotify.value
      if (richEditor.value) {
        initialHtmlContent.value = richEditor.value.getData()
      }
      isChangedHtmlContent.value = false
      files.value = []
    }

    const unloadHandler = () => {
      removeFormDraft()
    }
    useEventListener(window, 'unload', unloadHandler)

    const confirmContinueHandler = async () => {
      console.log('ConfirmContinue NoteEditForm ContinueWithoutSavingConfirmed')
      resetInitialValues()
      await nextTick()
      removeFormDraft()
      emit('hasChanges', false, (props.isNew ? 'top' : 'bottom'))
    }

    watch(hasChanges, () => {
      console.log('setNoteFormChanged watch hasChanges.value', hasChanges.value)
      emit('hasChanges', hasChanges.value, (props.isNew ? 'top' : 'bottom'))
    })

    watch(isPrivate, () => {
      console.log('NoteEditForm Draft call saveFormDraft isPrivate', isPrivate.value)
      saveFormDraft()
    })

    watch(emailIds, () => {
      console.log('NoteEditForm Draft call saveFormDraft emailIds', emailIds.value)
      saveFormDraft()
    })

    watch(files, () => {
      if (files.value.length) {
        isMinimized.value = false
      }
      console.log('NoteEditForm Draft call saveFormDraft activeAttachmentsCount', JSON.stringify(files.value))
      saveFormDraft()
    })

    watch(() => refNoteUploader.value?.uploadActive, value => {
      console.log('NoteEditForm watch uploadActive', value)
      emit('changeUploadActive', value, (props.isNew ? 'top' : 'bottom'))
    })

    const usersForEmailSelector = computed(() => store.getters['usersForFilter'])
    const selectedIdsModelForEmailSelector = computed(() => emailIds.value.length ? emailIds.value.split(',') : [])
    // For testing. todo: make users objects
    //const usersForEmailList = computed(() => selectedIdsModelForEmailSelector.value)
    const usersForEmailList = ref<IUser[]>([])
    const seekerUsersRequestData = computed(() => ({ticket: props.ticket_id}))

    const richEditor = ref<InstanceType<typeof RichEditor> | null>(null)

    const {
      isSuccessServerResponse,
      showResponseSuccessMessage,
    } = useAppUtils()

    const {
      getNoteFormDraft,
      isNoteDraftForTicketTopForm,
      isNoteDraftForNoteReply,
      isNoteDraftForNoteEdit,
      saveNoteDraftToStorage,
      removeNoteDraftFromStorage,
    } = useNoteDraft()

    let isDraftActive = false

    watch(() => props.ticket_id, () => {
      console.log('NoteEditForm props.note Draft saveFormDraft watch props.ticket_id', props.ticket_id)
      isDraftActive = false
    })

    onMounted(async () => {
      console.log('NoteEditForm onMounted', formUid.value, props, richEditor.value)
      console.log('NoteEditForm props.note onMounted', JSON.stringify(props.note))
      resetInitialValues()
      if (props.note) {
        setNoteData(props.note)
      }
      if (!props.isNew) {
        await initBottomNoteForm()
      } else {
        console.log('NoteEditForm props.note', JSON.stringify(props.note))
        watch(() => props.note, async () => {
          console.log('NoteEditForm props.note watch', JSON.stringify(props.note))
          await initTopNoteForm()
        }, {
          immediate: true
        })
      }
      emitter.off('ContinueWithoutSavingConfirmed', confirmContinueHandler)
      emitter.on('ContinueWithoutSavingConfirmed', confirmContinueHandler)

    })

    const initTopNoteForm = async () => {
      if (!props.note) {
        return
      }
      setNoteData(props.note)
      //await nextTick()
      const formDraft = getNoteFormDraft('top')
      console.log('NoteEditForm initTopNoteForm isNoteDraftForTicketTopForm', isNoteDraftForTicketTopForm(formDraft, props.ticket_id), props.ticket_id)
      if (isNoteDraftForTicketTopForm(formDraft, props.ticket_id)) {
        console.log('NoteEditForm initTopNoteForm applyDraftData', props.ticket_id)
        applyDraftData(formDraft)
        isMinimized.value = false
      }

      //await nextTick()
      isDraftActive = true
      //focusRichEditor()
      console.log('NoteEditForm Draft saveFormDraft isDraftActive = true', isDraftActive)
    }

    const initBottomNoteForm = async () => {
      await getInitialNoteData()

      if (props.reply) {
        await nextTick()

        const rightPanelElement = document.querySelector('.screen-panel-right')
        if (rightPanelElement) {
          const replyFormElement = rightPanelElement.querySelector(`[data-parent="${props.reply}"]`)
          if (replyFormElement) {
            replyFormElement.scrollIntoView({
              block: "nearest",
              inline: "nearest",
              behavior: "smooth",
            })
          }
        }

      }

      const formDraft = getNoteFormDraft('bottom')
      if ((props.id && isNoteDraftForNoteEdit(formDraft, props.id)) || (props.reply && isNoteDraftForNoteReply(formDraft, props.reply))) {
        applyDraftData(formDraft)
      }

      isDraftActive = true

    }

    onBeforeRouteUpdate(async () => {
      cancel()
    })

    const getInitialNoteData = async () => {
      showElementLoader(`[data-form-uid="${formUid.value}"]`, {scale: 0.35})
      const params: IData = {
      }
      if (props.id) {
        params.id = props.id
      }
      if (props.reply) {
        params.reply = props.reply
      }
      if (props.ticket_id) {
        params.ticket_id = props.ticket_id
      }
      const blockType = 'NoteEditForm_editNote'
      autorefreshBlockAdd(blockType)
      try {
        const response = await NotesService.editNote(params)
        if (isSuccessServerResponse(response)) {
          const noteObj = response.xdata?.note
          if (noteObj) {
            setNoteData(noteObj)
            await nextTick()
            await focusRichEditor()
          }
          store.commit('setStorageLimitReached', !!response?.xdata?.storageLimitReached)
        }
      } catch (e) {
        console.log('NoteEditForm getInitialNoteData error', e)
        await cancel()
      }
      autorefreshBlockRemove(blockType)
      await hideElementLoader(`[data-form-uid="${formUid.value}"]`)
    }

    const getEmailNotifySeekerItems = async (params: {itemsRef: Ref<any[]>, filterText: string, type: string | undefined, requestData: IData | undefined, loadingRef: Ref<boolean>, refFloatingComponent: Ref<InstanceType<typeof FloatingContainer> | null>}) => {
      console.log('SeekerUsers getSeekerItems', Date.now(), params)
      let data
      const {itemsRef, filterText, type, requestData, loadingRef, refFloatingComponent} = params
      try {
        loadingRef.value = true
        data = await SeekersService.getSeekerUsersItems(Object.assign({
          key: filterText,
          filter_type: type,
        }, requestData))
        console.log('SeekerUsers getSeekerItems success', data)
        itemsRef.value = Array.isArray(data) ? data : []
        if (noteObj.value?.externalUser?.id) {
          itemsRef.value.unshift({
            id: "separator_" + Math.round(Math.random() * 10000000000),
            type: "separator"
          })
          itemsRef.value.unshift({
            ...noteObj.value.externalUser,
            text: noteObj.value.externalUser.name,
          })
        }

        loadingRef.value = false

        console.log('SeekerUsers refFloatingComponent.value.refreshPosition', refFloatingComponent.value?.refreshPosition)
        await nextTick()
        setTimeout(() => { refFloatingComponent.value?.refreshPosition() }, 1)


      } catch (e) {
        console.log('SeekerUsers getSeekerItems error', e)
      }
    }

    const setNoteData = (note: INote) => {
      initialNoteData = {...note}
      noteObj.value = _merge({}, initialNoteData)
      htmlContent.value = note.text || ''
      isPrivate.value = note.private || null
      isEmailNotify.value = (note.notify_users && note.users && note.users.length) ? 1 : 0
      initialIsEmailNotify.value = isEmailNotify.value
      emailIds.value = (note.users || []).join(',')
      initialEmailIds.value = emailIds.value

      usersForEmailList.value = []
      if (selectedIdsModelForEmailSelector.value.length) {
        usersForEmailSelector.value.forEach(item => {
          if (selectedIdsModelForEmailSelector.value.includes(item.key.toString())) {
            usersForEmailList.value.push(item.data)
          }
        })
      }
      if (noteObj.value?.externalUser?.id && noteObj.value?.users?.includes(noteObj.value.externalUser.id)) {
        usersForEmailList.value.unshift({
          ...noteObj.value.externalUser
        })
      }


      if (richEditor.value) {
        richEditor.value.setData(htmlContent.value)
        initialHtmlContent.value = richEditor.value.getData()
      }
      resetInitialValues()
      emit('hasChanges', false, (props.isNew ? 'top' : 'bottom'))
    }

    const cancel = async () => {
      isMinimized.value = true
      fieldErrors.value = {}
      destroyFormErrors()
      emit('cancel')
      emit('hasChanges', false, (props.isNew ? 'top' : 'bottom'))
      const newUploadedFilesIds: string[] = []
      files.value.forEach(fileItem => {
        fileItem.active = false
        if (fileItem.response && fileItem.response.id) {
          newUploadedFilesIds.push(fileItem.response.id)
        }
      })
      if (newUploadedFilesIds.length) {
        try {
          await NotesService.removeAttachment({
            id: newUploadedFilesIds.join(','),
            perm: 0
          }, '', {silent: true})
        } catch (e) {
          console.log('NoteEditForm cancel removeAttachment error', e)
        }
      }
      files.value = []
      console.log('NoteEditForm cancel', newUploadedFilesIds.join(','), initialNoteData, files)
      if (initialNoteData) {
        setNoteData(initialNoteData)
      }
      if (typeof refNoteUploader.value?.resetUploader === "function") {
        refNoteUploader.value.resetUploader()
      }
      //resetInitialValues()
      await nextTick()
      removeFormDraft()
    }

    // id?: number
    // owner?: IUser,
    // text?: string,
    // insert_time?: string
    // update_time?: string
    // private?: number
    // notify_users?: number
    // users: string[]
    // files?: { imageList?: IData[]; fileList?: IData[]; videoList?: IData[]; }

    const getNoteData = () => {
      const noteObjValue = noteObj.value
      if (!noteObjValue) {
        return null
      }
      const data: INote = {
        id: noteObjValue.id,
        text: richEditor.value.getData(), //htmlContent.value,
        // id: props.note.id,
        // ticket_id: props.note.ticket_id,
        private: isPrivate.value ? 1 : 0,
        // email: isEmailNotify.value,
        // email_ids: emailIds.value,
        // reply: props.note?.parent_id,
        owner_id: noteObjValue.owner_id,
        project_id: noteObjValue.project_id,
        ticket_id: noteObjValue.ticket_id,
        depth: noteObjValue.depth || '0',
        type: noteObjValue.type,
        owner: Object.assign({}, noteObjValue.owner),
        insert_time: '',
        parent_id: noteObjValue.parent_id,
      }

      if (!data.id) {
        data.local_id = _uniqueId('new_note_')
      }

      console.log('getNoteData', data, JSON.stringify(data))
      console.log('getNoteData noteObjValue', JSON.stringify(noteObjValue))
      return data
    }

    //const isUploadInProgress = () => !!files.value.find(item => item.active)

    const isSaving = ref(false)
    const save = async (event) => {
      console.log('NoteEditForm save', JSON.stringify(noteObj.value), 'isSaving', isSaving.value)

      if (isSaving.value) {
        return
      }

      if (refNoteUploader.value?.uploadActive) {
        showWarning(t('please wait for the file(s) to upload'), {id: 'wait_for_the_file_upload'})
        return
      }


      isSaving.value = true
      const noteData = getNoteData()
      //const noteLocalId = _uniqueId('new_note_')
      showElementLoader(`[data-form-uid="${formUid.value}"]`, {scale: 0.19})
      isMinimized.value = !!props.isNew

      const blockType = 'NoteEditForm_save'
      const blockAttrs = {id: noteData?.id || '' }
      // AVB: теоретически юзер может нажать Save note, потом пока идет запрос на сохранение,
      // может успеть снова зайти в редактирование этого ноута и снова нажать save.
      // Поэтому тут НЕ блокируем возможность нескольких запросов
      // (Множественные клики блокируются с помощью isSaving
      // if (autorefreshBlockExists(blockType, blockAttrs)) {
      //   return false
      // }
      autorefreshBlockAdd(blockType, blockAttrs)

      emit('beforeSave', {
        note: noteData,
        //localId: noteLocalId,
        positionNote: props.positionNote
      })

      fieldErrors.value = {}

      try {
        const params: IData = {
          text: richEditor.value.getData(), //htmlContent.value,
          // id: props.note.id,
          // ticket_id: props.note.ticket_id,
          private: isPrivate.value,
          email: isEmailNotify.value,
          email_ids: emailIds.value,
          // reply: props.note?.parent_id,
          form_id: formUid.value,
        }
        if (props.id) {
          params.id = props.id
        }
        if (props.reply) {
          params.reply = props.reply
        }
        if (props.ticket_id) {
          params.ticket_id = props.ticket_id
        }

        const saveResult = await NotesService.saveNote(params)
        console.log('NoteEditForm save result', saveResult)
        resetInitialValues()
        emit('saveSuccess', {
          note: saveResult.xdata?.note,
          local_id: noteData?.local_id,
          positionNote: props.positionNote
        })
        emit('hasChanges', false, (props.isNew ? 'top' : 'bottom'))

        if (props.isNew && initialNoteData) {
          setNoteData(initialNoteData)
        }

        files.value = []
        if (typeof refNoteUploader.value?.resetUploader === "function") {
          refNoteUploader.value.resetUploader()
        }


//        isMinimized.value = true

        //alert(saveResult.message)
        // await store.dispatch('addNotification', {
        //   text: saveResult.message
        // })
        await hideElementLoader(`[data-form-uid="${formUid.value}"]`)
        await showResponseSuccessMessage(saveResult)

        await nextTick()
        removeFormDraft()

      } catch (e) {

        console.log('NoteEditForm save error', e)

        hideElementLoader(`[data-form-uid="${formUid.value}"]`)
        isMinimized.value = false

        if (e?.xdata?.errors) {
          fieldErrors.value = { ... e.xdata.errors }

          if (!isFormErrorFieldsRegistered) {
            registerFormErrorFields()
          }

          checkFormErrors()
        }

        emit('saveError', e)
      }
      isSaving.value = false
      autorefreshBlockRemove(blockType, blockAttrs)
    }

    const emailUsersSelectedHandler = (data) => {
      console.log('emailUsersSelectedHandler', data)
      emailIds.value = data.ids.join(',')
      isEmailNotify.value = data.ids.length ? 1 : 0

/*
      usersForEmailList.value = []
      if (selectedIdsModelForEmailSelector.value.length) {
        usersForEmailSelector.value.forEach(item => {
          if (selectedIdsModelForEmailSelector.value.includes(item.key.toString())) {
            usersForEmailList.value.push(item.data)
          }
        })
      }
*/

      const selectedItems: IUser[] = []
      //data.items.forEach(item => selectedItems.push(item.data))
      usersForEmailSelector.value.forEach((item) => {
        data.ids.forEach((userId) => {
          if (userId == item.key) { // not ===
            selectedItems.push(item.data)
          }
        })
      })
      usersForEmailList.value = selectedItems

      if (noteObj.value?.externalUser?.id && data.ids.includes(noteObj.value.externalUser.id)) {
        usersForEmailList.value.unshift({
          ...noteObj.value.externalUser,
        })
      }

    }

    const removeUserFromEmailList = (id:string) => {
      const index = usersForEmailList.value.findIndex(item => item.id === id)
      //console.log('removeUserFromEmailList findIndex', index)
      if (index !== -1) {
        usersForEmailList.value.splice(index, 1)
      }
      emailIds.value = usersForEmailList.value.map(item => item.id).join(',')
      isEmailNotify.value = usersForEmailList.value.length ? 1 : 0
    }

    const removeAttachment = async (id:string, listType?: string) => {
      console.log('removeAttachment', id, listType)
      const fileItem = refNoteUploader.value?.getFileItemById(id)
      let fileIdForServer = ''
      if (!fileItem) { // ранее сохраненный файл
        fileIdForServer = id
      } else if (fileItem.response && fileItem.response.code == 0) {
        fileIdForServer = fileItem.response.id
      }

      if (fileItem && refNoteUploader.value) {
        refNoteUploader.value.removeFileItem(fileItem)
      }

      if (listType) {
        const list = noteObj.value && noteObj.value?.files && noteObj.value?.files[listType]
        if (Array.isArray(list)) {
          const index = list.findIndex(item => item.id === id)
          if (index !== -1) {
            list.splice(index, 1)
          }
        }
      }

      saveFormDraft()

      if (fileIdForServer) {
        const blockType = 'NoteEditForm_removeAttachment'
        const blockAttrs = {id: fileIdForServer}

        if (autorefreshBlockExists(blockType, blockAttrs)) {
          return false
        }

        autorefreshBlockAdd(blockType, blockAttrs)
        try {
          const response = await NotesService.removeAttachment({
            id: fileIdForServer,
            perm: 0
          })
          if (isSuccessServerResponse(response)) {
            store.commit('setStorageLimitReached', !!response?.xdata?.storageLimitReached)
          }
          // Не показываем сообщение Attachment removed, т.к. удаление применится только после Save.
          // await showResponseSuccessMessage(response)
        } catch (e) {
          console.log(e)
        }
        autorefreshBlockRemove(blockType, blockAttrs)
      }

    }

/*
    const abortAttachmentUpload = (id:string) => {
      const fileItem = getUploaderFileItemById(id)
      if (fileItem && refFileUpload.value) {
        refFileUpload.value.update(fileItem, {active: false})
      }
    }

    const retryAttachmentUpload = (id:string) => {
      const fileItem = getUploaderFileItemById(id)
      if (fileItem && refFileUpload.value) {
        refFileUpload.value.update(fileItem, {active: true, error: '', progress: '0.00'})
      }
    }
*/



    const refFilesButtonTop = ref()
    const refFilesButtonBottom1 = ref()
    const refFilesButtonBottom2 = ref()

    const refFilesMenu = ref<InstanceType<typeof VMenu> | null>(null)
    const {isExist: isExistFilesMenu, triggerHandler: triggerHandlerFilesMenu} = useFloatingExists(refFilesMenu)
    const filesMenuPositionTarget = ref()

    const filesButtonClickHandler = event => {
      console.log('filesButtonClickHandler', event.target.closest('a'), event.target)
      if (addingAttachmentsDisabled.value) {
        alertFilesCountLimit()
      } else {
        filesMenuPositionTarget.value = event.target.closest('a')
        triggerHandlerFilesMenu()
      }
    }

    const {
      showFeatureNotImplemented,
      showElementLoader,
      hideElementLoader,
      showWarning,
    } = useAppUtils()

    const filesMenuActionHandler = async (action: string) => {
      triggerHandlerFilesMenu()
      if (action !== 'MyComputer') {
        await showFeatureNotImplemented()
        // await store.dispatch('addNotification', {
        //   id: 'feature_not_implemented',
        //   text: 'This feature is not implemented yet',
        //   type: 'error',
        // })

      }
    }

    const markFormActiveForPasteUpload = (event) => {
      const isActive = !['save', 'cancel', 'delete'].includes(event.target.dataset.action)
      console.log('markFormActiveForPasteUpload', formUid.value, event.target.dataset.action, isActive)
      isActive ? markActiveForPasteUpload(formUid.value) : markInactiveForPasteUpload(formUid.value)
    }

    const {
      isActiveForUpload,
      pasteHandler,
      markActiveForPasteUpload,
      markInactiveForPasteUpload,
    } = usePasteUpload(
      refNoteUploader,
      formUid,
      (props.isNew ? 'top' : 'bottom'),
    )

    // const richEditorPasteHandler = (event) => {
    //   console.log('richEditorPasteHandler', event)
    //   pasteHandler(event)
    // }
    //
    // const richEditorInputHandler = () => {
    //   console.log('richEditorInputHandler')
    //   isMinimized.value = false
    // }

    const { openFancybox } = useFancybox()

    const refNoteTextContainer = ref()

    const fieldErrors = ref<IFieldErrors>({})

    const removeFieldError = (fieldName: string) => {
      delete fieldErrors.value[fieldName]
      console.log('NoteEditForm removeFieldError', JSON.stringify(fieldErrors.value))
    }


    const {
      registerField,
      checkFormErrors,
      hideFieldError,
      destroyFormErrors,
    } = useFormErrors('noteForm_' + (props.isNew ? 'top' : 'bottom'), {
      refResizeObserverTarget: refNoteTextContainer,
      onRemoveError: removeFieldError
    })


    let isFormErrorFieldsRegistered = false
    const registerFormErrorFields = () => {
      registerField({
        fieldName: 'text',
        elementRefGetter: () => refNoteTextContainer.value && refNoteTextContainer.value.querySelector('.rich-editor'),
        errorMessageGetter: () => fieldErrors.value?.text,
        tippyProps: {
          //sticky: true,
          appendTo: () => document.querySelector('.right-panel-content')
        }
      })

      isFormErrorFieldsRegistered = true
    }

    onUpdated(() => {
      if (fieldErrors.value && Object.keys(fieldErrors.value).length) {
        checkFormErrors()
      }
    })

    onBeforeUnmount(() => {
      destroyFormErrors()
      //richEditor.value?.destroy()
      emitter.off('ContinueWithoutSavingConfirmed', confirmContinueHandler)
    })

    // const richEditorFocusHandler = () => {
    //   console.log('richEditorFocusHandler')
    //   isMinimized.value = false
    //   if (fieldErrors.value?.text) {
    //     hideFieldError('text')
    //     removeFieldError('text')
    //   }
    // }

    // const richEditorClickHandler = () => {
    //   console.log('richEditorClickHandler')
    //   isMinimized.value = false
    // }

    const focusRichEditor = async () => {
      await nextTick()
      console.log('focusRichEditor', richEditor.value)
      richEditor.value?.focus()
    }

    // const richEditorReadyHandler = () => {
    //   console.log('richEditorReadyHandler', props.isNew, richEditor.value)
    //   !props.isNew && focusRichEditor()
    // }

    const refUsersSeekerPositionTarget = ref<HTMLElement | null>(null)
    const refSeekerUsers = ref<InstanceType<typeof SeekerUsers> | null>(null)
    const {isExist: isExistSeekerUsers, triggerHandler: triggerHandlerSeekerUsers} = useFloatingExists(refSeekerUsers)

    const addUsersClickHandler = async (event) => {
      //refSeekerUsers.value.isOpened = !refSeekerUsers.value.isOpened
      await triggerHandlerSeekerUsers()
      await nextTick()
      if (refSeekerUsers.value.isOpened) {
        //emailIds.value = (noteObj.value?.users || []).join(',')
        refUsersSeekerPositionTarget.value = event.target.closest('button, a')
        await nextTick()
        refSeekerUsers.value.focusFilterField()
      }
    }

    const editorContainerClickHandler = () => {
      if (isMinimized.value) {
        console.log('RichEditor editorContainerClickHandler', richEditor.value?.$el, richEditor.value)
        isMinimized.value = false
        focusRichEditor()
      }
    }

    const richEditorChangeHandler = (value, innerHTML) => {
      console.log('richEditorChangeHandler', '|', value, '|', innerHTML, '|')
      if (isMinimized.value) {
        isMinimized.value = false
      }
      isChangedHtmlContent.value = initialHtmlContent.value !== value
      console.log('NoteEditForm Draft call saveFormDraft richEditorChangeHandler', value)
      saveFormDraft()
    }


    const refRichEditorToolbar = ref()
    const placeRichEditorToolbar = (params) => {
      console.log('placeRichEditorToolbar', params)
      if (refRichEditorToolbar.value && params.toolbarElement) {
        refRichEditorToolbar.value.innerHTML = ''
        refRichEditorToolbar.value.appendChild(params.toolbarElement)
      }
    }


    const isRichEditorFocused = ref(false)
    const isRichEditorTicketPluginOpened = ref(false)
    const isRichEditorToolbarOpened = computed(() => isRichEditorFocused.value || isRichEditorTicketPluginOpened.value)
    const richEditorFocusHandler = (value) => isRichEditorFocused.value = value
    const ticketPluginToggleHandler = value => isRichEditorTicketPluginOpened.value = value

    const richEditorReadyHandler = () => {
      console.log('richEditorReadyHandler', richEditor.value && richEditor.value.getData())
      if (richEditor.value) {
        initialHtmlContent.value = richEditor.value.getData()
      }
    }

    // const draftStorageType = 'localStorage'
    // const getDraftStorageKey = () => 'note_form_' + (props.isNew ? 'top' : 'bottom') + '_' + (authUser.value?.id || '')

    const getFormType = () => props.isNew ? 'top' : 'bottom'

    const isDraftForThisForm = () => {
      const formType = getFormType()
      const formDraft = getNoteFormDraft(formType)
      return formType === 'top' ? isNoteDraftForTicketTopForm(formDraft, props.ticket_id) : (props.id && isNoteDraftForNoteEdit(formDraft, props.id)) || (props.reply && isNoteDraftForNoteReply(formDraft, props.reply))
    }

    const removeFormDraft = () => {
      console.log('NoteEditForm Draft removeFormDraft')
      if (isDraftForThisForm()) {
        removeNoteDraftFromStorage(getFormType())
        //removeBrowserStorageItem(getDraftStorageKey(), draftStorageType)
      }
    }

    const saveFormDraft = () => {
      console.log('NoteEditForm Draft saveFormDraft', isDraftActive, hasChanges.value, richEditor.value?.getData()?.trim(), activeAttachmentsCount.value, typeof isPrivate.value, isPrivate.value)
      if (!isDraftActive) {
        return false
      }

      const textContent = richEditor.value?.getData()?.trim()
      // if (!(hasChanges.value && textContent)) {
      //   removeFormDraft()
      // }
      if (hasChanges.value && (textContent || activeAttachmentsCount.value > 0)) {
        const data = getDataForDraft()
        saveNoteDraftToStorage(getFormType(), data)
        // setBrowserStorageItem(getDraftStorageKey(), JSON.stringify(data), draftStorageType)
      } else {
        removeFormDraft()
      }
    }

    const applyDraftData = (draft) => {
      const draftNote = draft.note
      htmlContent.value = draftNote.text
      if (richEditor.value) {
        richEditor.value.setData(htmlContent.value)
      }
      isChangedHtmlContent.value = initialHtmlContent.value !== htmlContent.value
      if (noteObj.value) {
        Object.assign(noteObj.value.files, draftNote.oldFiles)
        draftNote.newFiles.forEach(item => {
          files.value.push(item)
        })
      }
      isPrivate.value = draftNote.private
      isEmailNotify.value = draftNote.isEmailNotify
      emailIds.value = draftNote.emailIds

      usersForEmailList.value = []
      if (selectedIdsModelForEmailSelector.value.length) {
        usersForEmailSelector.value.forEach(item => {
          if (selectedIdsModelForEmailSelector.value.includes(item.key.toString())) {
            usersForEmailList.value.push(item.data)
          }
        })
      }

      if (draft.form_id) {
        formUid.value = draft.form_id
      }
    }

    const getDataForDraft = (): INoteFormDraft => {
      const data: INoteFormDraft = {
        id: props.id || '',
        reply: props.reply || '',
        ticket_id: props.ticket_id || '',
        note: {
          text: richEditor.value.getData(),
          private: isPrivate.value === null ? null : (isPrivate.value == 1 ? 1 : 0),
          isEmailNotify: isEmailNotify.value,
          emailIds: emailIds.value,
          //notify_users: isEmailNotify.value,
          //users: emailIds.value.split(','),
          oldFiles: {},
          newFiles: [],
        },
        form_id: formUid.value,
      }

      if (noteObj.value?.files) {
        data.note.oldFiles.imageList = [...(noteObj.value.files.imageList || [])]
        data.note.oldFiles.fileList = [...(noteObj.value.files.fileList || [])]
      }

      files.value.forEach(item => {
        if (item.success === true) {
          data.note.newFiles.push(item)
        }
      })

      return data
    }

    const isDebugMode = !!inject('isDebugMode')
    const dragEnter = ref(false)

    const {
      filesDraggingActive,
      addDropObserverItem,
      removeDropObserverItem,
    } = useFilesDrag()

    let dropObserverUid
    onMounted(() => {
      dropObserverUid = addDropObserverItem(refDropElement, {
        onDragEnter: () => { dragEnter.value = true },
        onDragLeave: () => { dragEnter.value = false },
      })
    })
    onBeforeUnmount(() => {
      removeDropObserverItem(dropObserverUid)
    })

    const makeCover = async (ticketId, attachmentId) => {
      const fileItem = refNoteUploader.value?.getFileItemById(attachmentId)
      let fileIdForServer = ''
      if (!fileItem) { // ранее сохраненный файл
        fileIdForServer = attachmentId
      } else if (fileItem.response && fileItem.response.code == 0) {
        fileIdForServer = fileItem.response.id
      }

      if (fileIdForServer) {
        emit('makeCover', ticketId, fileIdForServer)
      }

    }

    const alertFilesCountLimit = async () => {
      //alert(t('Maximum number of files per upload reached'));
      await store.dispatch('addNotification', {
        text: t('Maximum number of files per upload reached'),
        type: 'warning',
        id: 'Maximum number of files per upload reached'
      })

    }


    return {
      t,
      formUid,
      isDebugMode,
      noteObj,
      isMinimized,
      htmlContent,
      hasAttachments,
      isPrivate,
      isEmailNotify,
      emailIds,
      usersForEmailSelector,
      selectedIdsModelForEmailSelector,
      usersForEmailList,
      hasChanges,

      uploaderInputId,
      uploaderData,
      dropActive,
      dragEnter,
      filesDraggingActive,
      refDropElement,
      uploadAction: ApiService.getBaseURL() + 'upload.php',
      storageLimitReached,

      refNoteTextContainer,
      richEditor,

      refSeekerUsers,
      refUsersSeekerPositionTarget,
      isExistSeekerUsers,
      emailUsersSelectedHandler,
      seekerUsersRequestData,

      refFilesButtonTop,
      refFilesButtonBottom1,
      refFilesButtonBottom2,
      filesButtonClickHandler,

      refFilesMenu,
      isExistFilesMenu,
      filesMenuPositionTarget,
      filesMenuActionHandler,

      isSaving,

      cancel,
      save,
      //updateNoteText,
      files,
      uploadingFilesWithThumb,
      uploadingFilesWithoutThumb,
      activeAttachmentsCount,
      filesCountLimit,
      fileSizeLimit,
      addingAttachmentsDisabled,
      refNoteUploader,
      removeUserFromEmailList,
      removeAttachment,
      markFormActiveForPasteUpload,
      // richEditorPasteHandler,
      // richEditorInputHandler,
      //richEditorFocusHandler,
      openFancybox,
      // richEditorFocusHandler,
      // richEditorClickHandler,
      // richEditorReadyHandler,
      addUsersClickHandler,
      positionRightStartFlipShift,
      editorContainerClickHandler,
      richEditorChangeHandler,
      richEditorReadyHandler,
      focusRichEditor,
      richEditorFocusHandler,
      ticketPluginToggleHandler,
      isRichEditorToolbarOpened,
      refRichEditorToolbar,
      placeRichEditorToolbar,
      //isUploadInProgress,
      getEmailNotifySeekerItems,
      ticketCoverEnabled,
      makeCover,
      alertFilesCountLimit,
      hasAttachmentsWithThumbs,
      hasAttachmentsWithoutThumbs,
    }
  }

})
